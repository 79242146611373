<template>
  <div class="space-y-4">
    <article v-if="canEditItemLocations">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create Location',
            })
          "
          icon="file-document"
          text="Create Primary Location"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="pt-8">
      <asom-client-table
        v-if="!isLoading"
        :columns="tableColumns"
        :sortableColumns="tableColumns"
        :data="tableData"
      >
        <template v-slot:header_locationName>Primary Location Name</template>
        <template v-slot:action="scopedSlots" v-if="canEditItemLocations">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              size="sm"
              outline
              text="Delete"
              variant="error"
              @click="deleteLocationClicked(scopedSlots.rowData)"
            />
            <asom-button
              size="sm"
              outline
              text="Update"
              @click="navigateToUpdate(scopedSlots.rowData)"
            />
          </div>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
    <asom-modal
      title="Delete Location"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse">
        <div class="pl-4">
          <asom-button
            @click="onDeleteLocation"
            text="OK"
            :loading="isSubmitting"
          />
        </div>
        <div>
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import {
  getListOfInventoryLocations,
  deleteInventoryLocation,
} from "../../../../services/inventory.service";

export default {
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      locationsList: [],
      tableData: [],
      error: null,
      showModal: false,
      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters({
      canEditItemLocations: "auth/canEditItemLocations",
    }),
    tableColumns() {
      let columns = ["locationName", "remarks"];
      if (this.canEditItemLocations) columns.push("action");
      return columns;
    },
  },
  watch: {
    stationId() {
      this.getPageData();
    },
  },
  mounted() {
    if (this.stationId !== null) {
      this.getPageData();
    }
  },
  methods: {
    async getPageData() {
      this.isLoading = true;
      const resp = await getListOfInventoryLocations({ skip: 0, take: 100 });
      if (resp.success) {
        this.locationsList = get(resp.payload, "list", []);
        this.tableData = this.locationsList;
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    navigateToUpdate(selectedItem) {
      this.$router.push({
        name: "Update Location",
        params: selectedItem,
      });
    },
    deleteLocationClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.toggleModal(true);
    },
    toggleModal(value) {
      this.showModal = value;
    },
    async onDeleteLocation() {
      this.isSubmitting = true;
      const result = await deleteInventoryLocation({
        inventoryLocationId: get(this.selectedItem, "inventoryLocationId"),
      });
      if (result.success) {
        this.isSubmitting = false;
        this.toggleModal(false);
        this.getPageData();
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.toggleModal(false);
        this.$scrollTop();
      }
    },
  },
};
</script>
